<template>
  <div
    v-click-away="closeDropdown"
    class="dropdown relative"
    :class="{ 'z-50': isActive }"
  >
    <div ref="toggle" class="relative z-40">
      <slot
        name="toggle"
        :close="closeDropdown"
        :on-click="onClick"
        :is-active="isActive"
        :toggle-attributes="toggleAttributes"
      />
    </div>
    <div
      v-show="isActive"
      :id="listboxId"
      :class="dropdownClass"
      class="absolute top-0 bg-white shadow-2xl z-30 w-full"
      role="listbox"
    >
      <slot :is-active="isActive" :close="closeDropdown" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  dropdownClass?: string
  id: string
}>()

const isActive = ref(false)
const toggle = ref<HTMLDivElement | null>(null)

const listboxId = computed(() => props.id + '-content')

const toggleAttributes = computed<Record<string, string>>(() => ({
  role: 'combobox',
  'aria-expanded': isActive.value ? 'true' : 'false',
  'aria-controls': listboxId.value,
  'aria-haspopup': 'listbox',
}))

/**
 * Listener attached to window when the dropdown is opened.
 */
function onKeyDown(e: KeyboardEvent) {
  if (!toggle.value) {
    return
  }

  // Handle pressing escape when the dropdown is open.
  if (isActive.value && e.key === 'Escape') {
    e.preventDefault()
    closeDropdown()

    // If the toggle is a button, focus it after closing the dropdown so that
    // focus is not lost.
    const toggleElement = toggle.value.firstElementChild
    if (toggleElement && toggleElement instanceof HTMLButtonElement) {
      toggleElement.focus()
    }
    // Open the dropdown when it's closed and the arrow up or down are pressed.
  } else if (
    !isActive.value &&
    toggle.value.contains(document.activeElement) &&
    (e.key === 'ArrowDown' || e.key === 'ArrowUp')
  ) {
    e.preventDefault()
    isActive.value = true
  }
}

function onClick() {
  isActive.value ? closeDropdown() : openDropdown()
}

function openDropdown() {
  isActive.value = true
}

function closeDropdown() {
  isActive.value = false
}

onMounted(() => {
  window.addEventListener('keydown', onKeyDown)
})

onBeforeUnmount(() => {
  closeDropdown()
  window.removeEventListener('keydown', onKeyDown)
})
</script>

<script lang="ts">
export default {
  name: 'Dropdown',
}
</script>
